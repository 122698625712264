
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');



  $(document).ready(function(){
    $.each($('.price'), function(){
    var price = $(this).html();
    $(this).html(price.replace(/(\d*)(\.\d*)/,'<span>$1</span><small style="font-weight:bold;">$2</small>'));
    });
  });

$(document).ready(function(){
  $('[data-toggle="tooltip"]').tooltip();
});

